@import '../../../../scss/theme-bootstrap';

.gnav-tout {
  width: 100%;
  height: 100%;
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: $color-core-black;
    border-#{$ldirection}: 1px solid $color-white-op50;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    .gnav-link-tout__trigger:checked + .gnav-link-tout .gnav-link-tout__content & {
      opacity: 1;
      visibility: visible;
      transition: opacity 1.5s ease;
    }
  }
  &__content {
    &-wrapper-price,
    &-wrapper-rating-stars {
      display: inline-block;
    }
    &-wrapper-rating-stars {
      float: $rdirection;
      .rating-star {
        display: inline-block;
      }
    }
    &-wrapper-header,
    &-wrapper-copy,
    &-wrapper-last-row {
      margin-top: 12px;
    }
  }
  &__content-wrapper {
    .gnav-tout__media-padding & {
      padding-top: 21px;
    }
    &.gnav-tout-content-over-media {
      display: flex;
      flex-direction: column;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
    &-cta-link {
      .gnav-tout-content-over-media & {
        margin-#{$ldirection}: auto;
      }
      a {
        .gnav-tout-content-over-media & {
          @include cta-primary-white;
        }
      }
    }
  }
  &__content-container {
    .gnav-tout-content-over-media & {
      padding: 40px;
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  &__media {
    width: 100%;
  }
  &__media-padding {
    padding: 40px 26vh 0;
    @include breakpoint($tall-up) {
      padding: 19px 12vh 0;
    }
  }
  p {
    margin: 0;
  }
}
